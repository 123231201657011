import React, { useState } from 'react';
import { Grid, Typography, TextField, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import LeftArrow from "../../../../../assets/images/leftArrow.png";
import RightArrow from "../../../../../assets/images/rightArrow.png";

const ScheduledSessions = ({ }) => {
  const dispatch = useDispatch();

  const scheduledSessionList = [
    { id: 1, sessionName: 'Lorem ipsum is discussion for give succession plans', coHost: 'Sonia behwal', leadersInvited: '2', positionsSelected: 'CEO', scheduleDate: 'Live', scheduleTime: '2:00 pm - 4 :00 pm', status: 'In Progress', action: '' },
    { id: 2, sessionName: 'Rating discussion', coHost: 'Sonia behwal', leadersInvited: '3', positionsSelected: 'CFO', scheduleDate: '20 Nov, 2024', scheduleTime: '2:00 pm - 4 :00 pm', status: 'Not Started', action: '' },
    { id: 3, sessionName: 'Lorem ipsum is discussion for give succession plans', coHost: 'Sonia behwal', leadersInvited: '2', positionsSelected: 'CRO', scheduleDate: '20 Nov, 2024', scheduleTime: '2:00 pm - 4 :00 pm', status: 'Rescheduled', action: '' },
    { id: 4, sessionName: 'Session for Rating discussion', coHost: 'Sonia behwal', leadersInvited: '5', positionsSelected: 'VP india', scheduleDate: '20 Nov, 2024', scheduleTime: '2:00 pm - 4 :00 pm', status: 'Completed', action: 'View Session' },
    { id: 5, sessionName: 'Lorem ipsum is discussion for give succession plans', coHost: 'Sonia behwal', leadersInvited: '4', positionsSelected: 'VP USA', scheduleDate: '20 Nov, 2024', scheduleTime: '2:00 pm - 4 :00 pm', status: 'Completed', action: 'View Session' }
  ];

  return (
    <div>
      <div className="scheduled-session-card">
        <table class="scheduled-session-table">
          <tr className="table-heading">
            <th><Typography className="table-head-label pl-20">{"Session Name"}</Typography></th>
            <th><Typography className="table-head-label">{"Co host"}</Typography></th>
            <th><Typography className="table-head-label">{"Leaders Invited"}</Typography></th>
            <th><Typography className="table-head-label">{"Positions selected"}</Typography></th>
            <th><Typography className="table-head-label">{"Schedule"}</Typography></th>
            <th><Typography className="table-head-label">{"Status"}</Typography></th>
            <th><Typography className="table-head-label pr-20">{"Action"}</Typography></th>
          </tr>

          {scheduledSessionList?.map((item, index) => {
            return (
              <tr>
                <td>
                  <Typography className="table-value pl-20">{item.sessionName}</Typography>
                </td>
                <td>
                  <Typography className="table-value">{item.coHost}</Typography>
                </td>
                <td>
                  <Typography className="table-value">{item.leadersInvited}</Typography>
                </td>
                <td>
                  <div className="d-flex">
                    <Typography className="table-value">{item.positionsSelected}</Typography> <span className="post-count">+2</span>
                  </div>
                </td>
                <td>
                  <div>
                    <Typography className="table-value">{item.scheduleDate}</Typography>
                    <Typography className="table-value scheduled-time">{item.scheduleTime}</Typography>
                  </div>
                </td>
                <td>
                  <Typography className="table-value">{item.status}</Typography>
                </td>
                <td>
                  <div className="pr-20">
                    <Typography className="table-head-label">{item.action}</Typography>

                    {/* <button className="start-btn">
                      {"Start"}
                    </button> */}
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      </div>

      {/*----- Pagination -----*/}
      <div className="d-flex jc-end mt-20">
        <div className="d-flex">
          <img src={LeftArrow} alt="LeftArrow" className="left-right-arrow" />
          <div className="pagination-border"><Typography className="pagination-count">{"1"}</Typography></div>
          <div className="pagination-border"><Typography className="pagination-count">{"2"}</Typography></div>
          <div className="pagination-border"><Typography className="pagination-count">{"3"}</Typography></div>
          <div className="pagination-border"><Typography className="pagination-count">{"4"}</Typography></div>
          <div className="pagination-border"><Typography className="pagination-count">{"5"}</Typography></div>
          <img src={RightArrow} alt="RightArrow" className="left-right-arrow ml-10" />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ScheduledSessions);
