import { useEffect, useState } from "react";
import { Autocomplete, Checkbox, TextField, Typography, Grid, Box } from "@mui/material";
import { HiOutlineChevronDown } from "react-icons/hi2";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import RoleCloseIcon from "../../../../assets/images/successionPlan/roleClose.svg";
import MinusIcon from "../../../../assets/images/successionPlan/minusIcon.svg";
import PlusIcon from "../../../../assets/images/successionPlan/plusIcon.svg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


const SchedulingSession = () => {
    const history = useHistory();
    const [coHost, setCoHost] = useState([]);
    const [leadersToBeInvited, setLeadersToBeInvited] = useState([]);
    const [positionsToBeSelected, setPositionsToBeSelected] = useState([]);

    const coHostOptions = [
        { title: 'John Doe 1' },
        { title: 'John Doe 2' },
        { title: 'John Doe 3' },
        { title: 'John Doe 4' },
    ];

    const leadersToBeInvitedOptions = [
        { title: 'Micheal William' },
        { title: 'Wallace Mason' },
    ];

    const positionsToBeSelectedOptions = [
        { title: 'CEO (Savannah Nguyen)' }
        // { title: 'CEO (Savannah Nguyen)' },
        // { title: 'CFO (John Doe)' },
        // { title: 'COO (NA)' },
        // { title: 'COO (Albert Flores)' },
    ];

    return (
        <div className="scheduling-session-page">
            <div className="scheduling-session-container">

                {/*----- Scheduling session Heading -----*/}
                <Typography className="heading-label">{"Scheduling session"}</Typography>

                {/*----- Session Title -----*/}
                <div className="field-row">
                    <div className="w-100">
                        <Typography className="field-label" >{"Session Title"}</Typography>
                        <TextField
                            name="sessionTitle"
                            id="outlined-multiline-static"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={"To discuss succession plan for"}
                            sx={{
                                width: '100%',
                                mt: '6px',
                                "& fieldset": {
                                    borderRadius: '8px',
                                },
                            }}
                        />
                    </div>
                </div>


                {/*----- Co host & Leaders to be invited -----*/}
                <div className="field-row">
                    {/*----- Co host -----*/}
                    <div className="row-half">
                        <div>
                            <Typography className="field-label">{"Co host"}</Typography>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={coHostOptions}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.title}
                                value={coHost}
                                onChange={(event, newValue) => {
                                    setCoHost(newValue);
                                }}
                                popupIcon={<HiOutlineChevronDown />}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            style={{ marginRight: 8 }}
                                        // checked={selected}
                                        />
                                        {option.title}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select"
                                        size="small"
                                        fullWidth
                                        sx={{
                                            width: '100%',
                                            mt: '6px',
                                            "& fieldset": {
                                                borderRadius: '8px',
                                            },
                                        }}
                                    />
                                )}
                            />


                            {/* Selected values shown as chips under the input */}
                            <div className='role-container'>
                                {coHost.map((option, index) => (
                                    <div className='role-border-div'>
                                        <div className='d-flex ai-center'>
                                            <Typography className='role-type'>{option.title}</Typography>
                                            <img
                                                src={RoleCloseIcon}
                                                alt="RoleCloseIcon"
                                                height={12}
                                                width={12}
                                                className='role-close-Icon'
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/*----- Leaders to be invited -----*/}
                    <div className="row-half">
                        <div>
                            <Typography className="field-label">{"Leaders to be invited"}</Typography>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={leadersToBeInvitedOptions}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.title}
                                value={leadersToBeInvited}
                                onChange={(event, newValue) => {
                                    setLeadersToBeInvited(newValue);
                                }}
                                popupIcon={<HiOutlineChevronDown />}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            style={{ marginRight: 8 }}
                                        // checked={selected}
                                        />
                                        {option.title}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select"
                                        size="small"
                                        fullWidth
                                        sx={{
                                            width: '100%',
                                            mt: '6px',
                                            "& fieldset": {
                                                borderRadius: '8px',
                                            },
                                        }}
                                    />
                                )}
                            />


                            {/* Selected values shown as chips under the input */}
                            <div className='role-container'>
                                {leadersToBeInvited.map((option, index) => (
                                    <div className='role-border-div'>
                                        <div className='d-flex ai-center'>
                                            <Typography className='role-type'>{option.title}</Typography>
                                            <img
                                                src={RoleCloseIcon}
                                                alt="RoleCloseIcon"
                                                height={12}
                                                width={12}
                                                className='role-close-Icon'
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>


                {/*----- Positions to be selected & Schedule session -----*/}
                <div className="field-row">
                    {/*----- Positions to be selected -----*/}
                    <div className="row-half">
                        <div>
                            <Typography className="field-label">{"Positions to be selected"}</Typography>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={positionsToBeSelectedOptions}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.title}
                                value={positionsToBeSelected}
                                onChange={(event, newValue) => {
                                    setPositionsToBeSelected(newValue);
                                }}
                                popupIcon={<HiOutlineChevronDown />}
                                renderOption={(props, option, { selected }) => (
                                    <Grid container {...props} className='dropdown-roles-container'>
                                        <Grid sx={{ marginLeft: "20px" }}>
                                            <img
                                                src={MinusIcon}
                                                alt="MinusIcon"
                                                height={20}
                                                width={20}
                                            />
                                            <Box sx={{
                                                width: "1px",
                                                height: "100%",
                                                backgroundColor: "#000000",
                                                opacity: "10%",
                                                marginLeft: "10px",
                                            }} />
                                        </Grid>

                                        {/*----- CEO (Savannah Nguyen) -----*/}
                                        <Grid>
                                            <div className="d-flex">
                                                <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px", marginLeft: "8px" }}>{"CEO (Savannah Nguyen)"}</Typography>
                                                <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                                            </div>

                                            <Grid container className='dropdown-roles-container'>
                                                <Grid sx={{ marginLeft: "10px" }}>
                                                    <img
                                                        src={MinusIcon}
                                                        alt="MinusIcon"
                                                        height={20}
                                                        width={20}
                                                    />
                                                    <Box sx={{
                                                        width: "1px",
                                                        height: "100%",
                                                        backgroundColor: "#000000",
                                                        opacity: "10%",
                                                        marginLeft: "10px",
                                                    }} />
                                                </Grid>

                                                {/*----- CFO (John Doe) -----*/}
                                                <Grid>
                                                    <div className="d-flex">
                                                        <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px", marginLeft: "8px" }}>{"CFO (John Doe)"}</Typography>
                                                        <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                                                    </div>


                                                    <Grid container className='dropdown-roles-container'>
                                                        <Grid sx={{ marginLeft: "10px" }}>
                                                            <img
                                                                src={MinusIcon}
                                                                alt="MinusIcon"
                                                                height={20}
                                                                width={20}
                                                            />
                                                            <Box sx={{
                                                                width: "1px",
                                                                height: "100%",
                                                                backgroundColor: "#000000",
                                                                opacity: "10%",
                                                                marginLeft: "10px",
                                                            }} />
                                                        </Grid>

                                                        {/*----- Financial controller (Kristin Watson) -----*/}
                                                        <Grid>
                                                            <div className="d-flex">
                                                                <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px", marginLeft: "8px" }}>{"Financial controller (Kristin Watson)"}</Typography>
                                                                <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                                                            </div>


                                                            <Grid container className='dropdown-roles-container'>
                                                                <Grid sx={{ marginLeft: "10px" }}></Grid>
                                                                <Grid>
                                                                    {/*----- VP Fin. India (NA) -----*/}
                                                                    <div className="d-flex">
                                                                        <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px" }}>{"VP Fin. India (NA)"}</Typography>
                                                                        <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                                                                    </div>

                                                                    {/*----- VP Fin. UK (Jerome Bell) -----*/}
                                                                    <div className="d-flex">
                                                                        <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px" }}>{"VP Fin. UK (Jerome Bell)"}</Typography>
                                                                        <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/*----- CRO (Cameron Williamson) -----*/}
                                            <Grid container className='dropdown-roles-container'>
                                                <Grid sx={{ marginLeft: "10px" }}>
                                                    <img
                                                        src={PlusIcon}
                                                        alt="PlusIcon"
                                                        height={20}
                                                        width={20}
                                                    />
                                                    {/* <Box sx={{
                                                        width: "1px",
                                                        height: "100%",
                                                        backgroundColor: "#000000",
                                                        opacity: "10%",
                                                        marginLeft: "10px",
                                                    }} /> */}
                                                </Grid>

                                                <Grid>
                                                    <div className="d-flex">
                                                        <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px", marginLeft: "8px" }}>{"CRO (Cameron Williamson)"}</Typography>
                                                        <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                                                    </div>
                                                </Grid>
                                            </Grid>


                                            {/*----- COO (Albert Flores) -----*/}
                                            <Grid container className='dropdown-roles-container'>
                                                <Grid sx={{ marginLeft: "10px" }}>
                                                    <img
                                                        src={PlusIcon}
                                                        alt="PlusIcon"
                                                        height={20}
                                                        width={20}
                                                    />
                                                    {/* <Box sx={{
                                                        width: "1px",
                                                        height: "100%",
                                                        backgroundColor: "#000000",
                                                        opacity: "10%",
                                                        marginLeft: "10px",
                                                    }} /> */}
                                                </Grid>

                                                <Grid>
                                                    <div className="d-flex">
                                                        <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px", marginLeft: "8px" }}>{"COO (Albert Flores)"}</Typography>
                                                        <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                                                    </div>
                                                </Grid>
                                            </Grid>


                                            {/*----- COO (NA) -----*/}
                                            <Grid container className='dropdown-roles-container'>
                                                <Grid sx={{ marginLeft: "10px" }}>
                                                    <img
                                                        src={PlusIcon}
                                                        alt="PlusIcon"
                                                        height={20}
                                                        width={20}
                                                    />
                                                    {/* <Box sx={{
                                                        width: "1px",
                                                        height: "100%",
                                                        backgroundColor: "#000000",
                                                        opacity: "10%",
                                                        marginLeft: "10px",
                                                    }} /> */}
                                                </Grid>

                                                <Grid>
                                                    <div className="d-flex">
                                                        <Typography sx={{ color: "#004259", fontSize: "16px", marginTop: "2px", marginLeft: "8px" }}>{"COO (NA)"}</Typography>
                                                        <Checkbox size="small" sx={{ marginRight: 5, marginTop: "-5px" }} />
                                                    </div>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select"
                                        size="small"
                                        fullWidth
                                        sx={{
                                            width: '100%',
                                            mt: '6px',
                                            "& fieldset": {
                                                borderRadius: '8px',
                                            },
                                        }}
                                    />
                                )}
                            />


                            {/* Selected values shown as chips under the input */}
                            <div className='role-container'>
                                {positionsToBeSelected.map((option, index) => (
                                    <div className='role-border-div'>
                                        <div className='d-flex ai-center'>
                                            <Typography className='role-type'>{option.title}</Typography>
                                            <img
                                                src={RoleCloseIcon}
                                                alt="RoleCloseIcon"
                                                height={12}
                                                width={12}
                                                className='role-close-Icon'
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>


                    {/*----- Schedule session -----*/}
                    <div className="row-half">
                        <div>
                            <Typography className="field-label">{"Schedule session"}</Typography>
                            <TextField
                                name="responseString"
                                id="outlined-multiline-static"
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{
                                    width: '100%',
                                    mt: '6px',
                                    "& fieldset": {
                                        borderRadius: '8px',
                                    },
                                }}
                                multiline
                                rows={0}
                            />
                        </div>
                    </div>
                </div>
            </div>


            {/*----- Cancel & Schedule Btn -----*/}
            <div className="submit-cancel-div gap-3">
                <button
                    className="cancel-btn"
                >
                    {"Cancel"}
                </button>

                <button
                    className="submit-btn"
                    onClick={() => { history.push("/hr-view-succession-plan")}}
                >
                    {"Schedule"}
                </button>
            </div>
        </div>
    )
}

export default SchedulingSession;
