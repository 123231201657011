const { createAction } = require('redux-actions');
const GOALCYCLE_DATA_REQUEST = 'MEDIBUDDY/GOALCYCLE_DATA_REQUEST';
const GOALCYCLE_DATA_REQUEST_SUCCESS = 'MEDIBUDDY/GOALCYCLE_DATA_REQUEST_SUCCESS';
const GOALCYCLE_DATA_REQUEST_FAILED = 'MEDIBUDDY/GOALCYCLE_DATA_REQUEST_FAILED';

const GoalcycleDataRequest = createAction(GOALCYCLE_DATA_REQUEST);
const GoalcycleDataRequestSuccess = createAction(GOALCYCLE_DATA_REQUEST_SUCCESS);
const GoalcycleDataRequestFailed = createAction(GOALCYCLE_DATA_REQUEST_FAILED);

export const actions = {
    GoalcycleDataRequest,
    GoalcycleDataRequestSuccess,
    GoalcycleDataRequestFailed,
};

export const types = {
    GOALCYCLE_DATA_REQUEST,
    GOALCYCLE_DATA_REQUEST_SUCCESS,
    GOALCYCLE_DATA_REQUEST_FAILED,
};