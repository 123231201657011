import React from 'react';
import { Dialog, Button, Grid, Typography, MenuItem, Select, Box, TextField } from '@mui/material';
import EditUserProfile from "../../../../../../assets/images/successionPlan/editUserProfile.svg";
import RoleCloseIcon from "../../../../../../assets/images/successionPlan/roleClose.svg";
import ArrowRightIcon from "../../../../../../assets/images/successionPlan/modalRightArrow.svg";


const SessionNotesModal = ({ showSessionNotes, handleSessionNotesNo }) => {

    return (
        <Dialog
            open={showSessionNotes}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: "1rem",
                    backgroundColor: "#F9F9F9",
                }
            }}
            fullWidth
            maxWidth="sm">
            <Grid container className="profile-edit">
                {/*----- Heading & Close Button -----*/}
                <Grid container item xs={12} md={12} sm={12} sx={{ justifyContent: "space-between", marginBottom: "10px" }}>
                    <Typography className="modal-heading">{"Session Notes"}</Typography>
                    <img
                        src={RoleCloseIcon}
                        alt="RoleCloseIcon"
                        height={12}
                        width={12}
                        className="modal-close-Icon"
                        onClick={handleSessionNotesNo}
                    />
                </Grid>


                <Grid item xs={12} md={8} sm={8}>
                    <Grid container>
                        <Typography className="you-moved">{"You moved"}</Typography>
                        <img
                            src={EditUserProfile}
                            alt="EditUserProfile"
                            height={30}
                            width={30}
                        />
                        <Typography className="user-name">{"Savannah Nguyen"}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} sm={4} className="date-end-grid">
                    <Typography className="modal-date-description">{"05 Aug 2024"}</Typography>
                </Grid>

                <Grid container item xs={12} md={12} sm={12} className="modal-ceo-grid">
                    <Typography className="modal-ceo">{"CEO (Ready Now)"}</Typography>
                    <img
                        src={ArrowRightIcon}
                        alt="ArrowRightIcon"
                        height={30}
                        width={30}
                        className="modal-right-arrow"
                    />
                    <Typography className="modal-ceo">{"CEO (Ready in 12)"}</Typography>
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="modal-date-description">{"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available."}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default SessionNotesModal;