import { TextField } from "@mui/material";
import UserProfile1Icon from "../../../assets/images/goalView/userProfile1.svg";
import UserProfile2Icon from "../../../assets/images/goalView/userProfile2.svg";
import CommentSendBtnIcon from "../../../assets/images/goalView/commentSendBtn.svg";
import ExpandAnyGoalBgIcon from "../../../assets/images/goalView/expandAnyGoalBg.svg";
import ExpandAnyGoalIcon from "../../../assets/images/goalView/expandAnyGoal.svg";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import APIList from "../../../api";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { useState } from "react";
import { isEmptyNullUndefined } from "../../utils/utils";
import moment from "moment";
import Avatar from "react-avatar";

const Comments = ({data, addComment, id, addCommentLoader, comment, setComment}) => {    

    return(    
     <div className="comments-card-layout">
         <div className="d-flex">
             <p className="comments-label">Comments</p>
             <p className="comment-count">{data?.length}</p>
         </div>

         {
            !isEmptyNullUndefined(id) && isEmptyNullUndefined(data) && 
            <div className="no-comment"> No comments found </div>
         }

         {/*----- Comment Card 1 -----*/}
         {
            !isEmptyNullUndefined(id) && !isEmptyNullUndefined(data) &&
            data?.map((comment) => {
               return(
                   <div className="comment-user-card">
                <div className="d-flex">
                    <div className="user-profile">
                        {/* <img
                            src={UserProfile1Icon}
                            alt="UserProfile1Icon"
                            height={40}
                            width={40}
                        /> */}
                        <div className="empPic">
                            {comment?.profilePicPath ? 
                                <img src={comment?.profilePicPath} className="userImg" alt="User" />
                                :
                                <Avatar 
                                    name={comment?.employeeName} 
                                    size="45" 
                                    className="userImg"
                                    color={"#00425A"}    
                                />
                            }
                        </div>
                    </div>
                    <div className="user-div">
                        <p className="userName">{comment?.employeeName}</p>
                        <p className="userPost">{comment?.employeeDesignation}</p>
                    </div>
                </div>
   
                <p className="user-comment">{comment?.comment}</p>
   
                <p className="comment-date">{moment(comment?.commentTimeStamp).format('DD MMM YYYY, hh:mmA')}</p>
            </div>
               )
            })
         }
         

         {/*----- Comment Card 2 -----*/}
         {/* <div className="comment-user-card">
             <div className="d-flex">
                 <div className="user-profile">
                     <img
                         src={UserProfile2Icon}
                         alt="UserProfile2Icon"
                         height={40}
                         width={40}
                     />
                 </div>
                 <div className="user-div">
                     <p className="userName">Harry mustain</p>
                     <p className="userPost">You</p>
                 </div>
             </div>

             <p className="user-comment">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to.</p>

             <p className="comment-date">03 Aug 2024, 12:46PM</p>
         </div> */}

         {/*----- Comment Card 3 -----*/}
         {/* <div className="comment-user-card comment-card-bg">
             <div className="d-flex">
                 <div className="user-profile">
                     <img
                         src={UserProfile1Icon}
                         alt="UserProfile1Icon"
                         height={40}
                         width={40}
                     />
                 </div>
                 <div className="user-div">
                     <p className="userName">John Doe</p>
                     <p className="userPost">Manager</p>
                 </div>
             </div>

             <p className="user-comment">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.</p>

             <p className="comment-date">03 Aug 2024, 12:46PM</p>
         </div> */}

         {/*----- Expand any goal to view its comments. Card -----*/}
         {
            isEmptyNullUndefined(id) && 
            <div className="comment-user-card">
             <div className="expandAnyGoal-Icon-div">
                 <img
                     src={ExpandAnyGoalBgIcon}
                     alt="ExpandAnyGoalBgIcon"
                     height={90}
                     width={90}
                     className="background-Icon"
                 />
                 <img
                     src={ExpandAnyGoalIcon}
                     alt="ExpandAnyGoalIcon"
                     height={73}
                     width={80}
                     className="expandAnyGoal-Icon"
                 />
             </div>
             <p className="expandAnyGoal">Expand any goal to view its comments.</p>
            </div>
         }
         

         {/*----- Border -----*/}
         <div className="comment-border" />

         {/*----- Comment TextField -----*/}
         {
            !isEmptyNullUndefined(id) && 
            <div className="comment-field-div">
             <TextField
                 placeholder="Enter your comment..."
                 variant="outlined"
                 size="small"
                 fullWidth
                 value={comment}
                 onChange={(e) => setComment(e.target.value)}                 
                 onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        addComment(comment);
                    }
                }}
                 sx={{
                     "& .MuiOutlinedInput-root": {
                         "& fieldset": {
                             border: "none",
                         },
                     },
                 }}
                 InputProps={{
                     endAdornment: (
                         <InputAdornment position="end">
                             <IconButton
                                 edge="end"
                                 color="primary"
                                 onClick={() => addComment(comment)}
                                 disabled={addCommentLoader}
                             >
                                 <img
                                     src={CommentSendBtnIcon}
                                     alt="CommentSendBtnIcon"
                                 />
                             </IconButton>
                         </InputAdornment>
                     ),
                 }}
             />
            </div>
         }
         

     </div>
    )
}

export default Comments;