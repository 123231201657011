import React, { useState } from 'react';
import { Grid, Typography, TextField, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import LeftArrow from "../../../../../assets/images/leftArrow.png";
import RightArrow from "../../../../../assets/images/rightArrow.png";

const DelegateList = ({ }) => {
  const dispatch = useDispatch();

  const delegateList = [
    { id: 1, leaderName: 'Esther Howard' },
    { id: 2, leaderName: 'Theresa Webb' },
    { id: 3, leaderName: 'Dianne Russell' },
    { id: 4, leaderName: 'Albert Flores' }
  ];

  return (
    <div>
      <div className="scheduled-session-card">
        <table class="scheduled-session-table">
          <tr className="table-heading">
            <th><Typography className="table-head-label pl-20">{"Leader Name"}</Typography></th>
            <th><Typography className="table-head-label pr-20 ta-end">{"Action"}</Typography></th>
          </tr>

          {delegateList?.map((item, index) => {
            return (
              <tr>
                <td>
                  <Typography className="table-value pl-20">{item.leaderName}</Typography>
                </td>
                <td>
                  <div className="pr-20 ta-end">
                    <button className="start-btn">
                      {"Edit Succession Plan"}
                    </button>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      </div>

      {/*----- Pagination -----*/}
      <div className="d-flex jc-end mt-20">
        <div className="d-flex">
          <img src={LeftArrow} alt="LeftArrow" className="left-right-arrow" />
          <div className="pagination-border"><Typography className="pagination-count">{"1"}</Typography></div>
          <div className="pagination-border"><Typography className="pagination-count">{"2"}</Typography></div>
          <div className="pagination-border"><Typography className="pagination-count">{"3"}</Typography></div>
          <div className="pagination-border"><Typography className="pagination-count">{"4"}</Typography></div>
          <div className="pagination-border"><Typography className="pagination-count">{"5"}</Typography></div>
          <img src={RightArrow} alt="RightArrow" className="left-right-arrow ml-10" />
        </div>
      </div>
    </div>
  );
};

export default React.memo(DelegateList);
