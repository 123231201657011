import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api/index';
import { actions, types } from './actions';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { actions as logInActions } from "../login/actions";
// import { actions as logInActions } from "../login/actions";

const GoalCycleDataRequest = function* GoalCycleDataRequest({ payload }) {
    try {
        const { data } = yield call(api.getGoalCycle, payload.companyId);
        if (data) {
            yield put(actions.GoalcycleDataRequestSuccess(data));
            // data && toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{width:"2rem",height:"2rem"}} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.GoalcycleDataRequestFailed());
            yield put(logInActions.clearLogInToken());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.GoalcycleDataRequestFailed());
        yield put(logInActions.clearLogInToken());

        // logout user if error
        // yield put(logInActions.clearLogInToken());
        // window.location.reload();        
    }
};

export default function* sagas() {
    yield takeLatest(types.GOALCYCLE_DATA_REQUEST, GoalCycleDataRequest);
}
