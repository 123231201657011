import React, { useState } from 'react';
import { Grid, Typography, TextField, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

const SuccessionAnalytics = ({ }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <Typography>{"Succession Analytics"}</Typography>
    </div>
  );
};

export default React.memo(SuccessionAnalytics);
