import { isEmptyNullUndefined } from "../../utils/utils";

export const errorCodeMessage = 'found error in code base, error code: '

export const isRequiredFun = (isRequired) => {

    if(isRequired) {
        return (
            {
                borderLeft: '4px solid red',
                borderRadius: '8px',                          
            }
        )
    } else {
        return (
            {
                // borderLeft: '4px solid red',
                borderRadius: '8px',                          
            }
        )
    }
}

export function ResponseErrorConstructorFun(isError = false, errorMessage = '') {  //use with const initialResponseError = new ResponseErrorConstructorFun();
    this.isError = isError;
    this.errorMessage = errorMessage;
}

// export const ResponseErrorConstructorObj = {  
//     isError: false,
//     errorMessage: ''
// }

//use with const initialResponseError = Object.create(ResponseErrorConstructorObj);

// export const ResponseErrorConstructorObj = (isError = false, errorMessage = '') => {
//     return { isError, errorMessage };
// };

export const mapResponseErrorUtil = async (tempgoalFormFieldForContainer, setIsLoading) => {
    let tempResponseError = []

    if(!Array.isArray(tempgoalFormFieldForContainer)) {
        console.log('found error in code, error id: 5s6d4f5s1df6s54f6sd ')
        return;
    } else if(!(tempgoalFormFieldForContainer.length > 0)) {
        console.log('found error in code, error id: sdfsd35f1d351s3f2112 ')
        return;
    }

    try{

        tempResponseError = tempgoalFormFieldForContainer.map((e,i) => {
            // const initialResponseError = Object.create(ResponseErrorConstructorObj);
            const initialResponseError = new ResponseErrorConstructorFun()
            // const initialResponseError = deepCloneObj(ResponseErrorConstructorObj())
            if(Array.isArray(e[0])) {
                // it is container

                e.map((conE, conI) => {
                   
                    conE[0].container.map((conFieldE, conFieldI) => {
                      
        
                        conFieldE[0].responseError = initialResponseError
        
                        if(conFieldE[1]){
                            conFieldE[1].responseError = initialResponseError
                        }
        
                        return conFieldE
                    })
        
                    return conE
                })
        
               
        
              
            } else {
                e[0].responseError = initialResponseError
        
                if(e[1]){
                    e[1].responseError = initialResponseError
                }

            }
        
           
            return e
        })

    } catch (error) {
        console.log(error.message)
    }


    return tempResponseError

}

// export const mapFormDataUtil = (response, responseContainer) => {

//     let tempResponseData = structuredClone(response.data)
//     let tempgoalFormField = structuredClone(response.data.responseFormField)
//     let tempcontainerFormField = structuredClone(responseContainer.data.responseFormField)

//     tempgoalFormField.sort((a, b) => a[0].parentSequenceNo - b[0].parentSequenceNo)

//     tempgoalFormField = tempgoalFormField.map((e,i) => {

//     if((e[0].isMultiField === false) || (e[0].isMultiField === "false")) {

//         return e
//     } else {
//         e.sort((a,b) => a.sequenceNo - b.sequenceNo)
//         return e
//     }
//     })


//     tempcontainerFormField.sort((a, b) => a[0].parentSequenceNo - b[0].parentSequenceNo)

//     tempcontainerFormField = tempcontainerFormField.map((e,i) => {
//     if((e[0].isMultiField === false) || (e[0].isMultiField === "false")) {

//                 if(e[0].type === 'container') {
//                     e[0].container.sort((a,b) => a[0].parentSequenceNo - b[0].parentSequenceNo)
//                     e[0].container = e[0].container.map((containerE, containerPI) => {
//                         if((containerE[0].isMultiField === false) || (containerE[0].isMultiField === "false")) {
                            
//                         return containerE
//                         } else {
//                         containerE.sort((a,b) => a.sequenceNo - b.sequenceNo)

//                         return containerE
//                         }
//                     })
//                 }


//         return e
//     }
//     })

//     let tempgoalFormFieldForContainer = structuredClone(tempgoalFormField)

//     tempcontainerFormField.forEach((e,i) => {
//     tempgoalFormField.splice(e[0].parentSequenceNo, 0, e)

//     tempgoalFormFieldForContainer.splice(e[0].parentSequenceNo, 0, [e]) 
//     })

//     tempResponseData.responseFormField = tempgoalFormField
    
//     return {tempResponseData, tempgoalFormFieldForContainer}
// }

export const getResponseFormField = (vertualPayload) => {
   
    let tempResponseFormField = []
    let tempResponseFormContainerField = []

    vertualPayload.forEach((e, i) => {
        if(!Array.isArray(e[0])) {
            tempResponseFormField.push(e)
        } else {
            tempResponseFormContainerField.push(e)
        }
    })

    if(tempResponseFormContainerField.length > 0) {
        tempResponseFormContainerField = tempResponseFormContainerField.map((containerE, containerI) => {
           
            containerE.map((tabE, tabI) => {
               
                tabE[0].similarContainerSequence = tabI

                return tabE

            })

            return containerE
        })
    }

    return {tempResponseFormField, tempResponseFormContainerField}


}

export const mapFormDataUtil = async (response, setIsLoading) => {

    try{
        let tempResponseData = structuredClone(response.data)
        let tempgoalFormField = structuredClone(response.data.responseFormField)
        let tempcontainerFormField = structuredClone(response.data.structureFormContainerField)
        let tempresponseFormContainerField = response?.data?.responseFormContainerField ? deepCloneObj(response.data.responseFormContainerField) : null

        tempgoalFormField.sort((a, b) => a[0].parentSequenceNo - b[0].parentSequenceNo)
    
        tempgoalFormField = tempgoalFormField.map((e,i) => {
    
        if((e[0].isMultiField === false) || (e[0].isMultiField === "false")) {
    
            return e
        } else {
            e.sort((a,b) => a.sequenceNo - b.sequenceNo)
            return e
        }
        })

 
        tempcontainerFormField.sort((a, b) => a[0].parentSequenceNo - b[0].parentSequenceNo)
    
        tempcontainerFormField = tempcontainerFormField.map((e,i) => {
        if((e[0].isMultiField === false) || (e[0].isMultiField === "false")) {
    
                    if(e[0].type === 'container') {
                        e[0].container.sort((a,b) => a[0].parentSequenceNo - b[0].parentSequenceNo)
                        e[0].container = e[0].container.map((containerE, containerPI) => {
                            if((containerE[0].isMultiField === false) || (containerE[0].isMultiField === "false")) {
                                
                            return containerE
                            } else {
                            containerE.sort((a,b) => a.sequenceNo - b.sequenceNo)
    
                            return containerE
                            }
                        })
                    }
    
    
            return e
        }
        })

     
        // return


    
        let tempgoalFormFieldForContainer = structuredClone(tempgoalFormField)
    
        tempcontainerFormField.forEach((e,i) => {
        tempgoalFormField.splice(e[0].parentSequenceNo, 0, e)
    
        tempgoalFormFieldForContainer.splice(e[0].parentSequenceNo, 0, [e]) 
        })
    
        tempResponseData.responseFormField = tempgoalFormField

        // ============bug found, if include previous code so actual data not have error obj==============


        

        !isEmptyNullUndefined(tempresponseFormContainerField) && tempresponseFormContainerField.forEach((containerResponseE, containerResponseI) => {


            if(!(containerResponseE && containerResponseE.length && containerResponseE[0].length)) {
                console.log(`${errorCodeMessage} rg484f5sd4f5r7s54fs5f45sdf45sf ,containerResponseI=${containerResponseI} not able to get parentSequenceNo`)
                return   
            }

            let { parentSequenceNo } = containerResponseE[0][0]

            
            

            // ====================

            containerResponseE.sort((a, b) => a[0].similarContainerSequence - b[0].similarContainerSequence)

            containerResponseE.map((containerFieldRowE, containerFirldRowI) => {
              

                containerFieldRowE[0].container.sort((a, b) => a[0].parentSequenceNo - b[0].parentSequenceNo)

                containerFieldRowE[0].container.map((rowFieldE, rowFieldI) => {
                    if(rowFieldE.length > 0) {
                        rowFieldE.sort((a, b) => a.sequenceNo - b.sequenceNo)
                    }

                    return rowFieldE
                })

                return containerFieldRowE
            })

            // ====================

            

            tempgoalFormFieldForContainer[parentSequenceNo] = containerResponseE
        })
        
        return {tempResponseData, tempgoalFormFieldForContainer, tempresponseFormContainerField}
        

    } catch (error) {
        console.log(error.message)
        console.log( `${errorCodeMessage} jyffygyu48986jguy`)
    }
    
   
}

export const checkLengthOfArrayEqualOrNot = ({...params}) => { 
    try {
        let totalKey = Object.keys(params)
        

        if(!Array.isArray || totalKey.length === 0) {
            return 
        } 

        let arrayIndex = 'initial'
        let isSameLength = true

        totalKey.some((e, i) => {
            if(!Array.isArray(params[e])) {
                isSameLength = false
                return
            }
            if(arrayIndex === 'initial') {
                arrayIndex = params[e].length
            } else {
                if(arrayIndex === params[e].length) {
                    arrayIndex = params[e].length
                } else {
                    isSameLength = false
                }
            }
        })

        
        return isSameLength
    } catch(error) {
        console.log(error.message)
        console.log(`${errorCodeMessage} 3s54fd6s5f1s5dfs351fd`)
    }
    

}

export const deepCloneObj = (data) => {
    if(isEmptyNullUndefined(data)) {
        return
    }

    return JSON.parse(JSON.stringify(data))
}

export const checkValidation = (vertualPayload, vertualError) => {
    if(isEmptyNullUndefined(vertualPayload) || isEmptyNullUndefined(vertualError)) {
        console.log(errorCodeMessage, 'sr4r54g54r87b8r7g8r4g5r')
        return {isValid: false, tempVertualError: []}
    }
    let tempvertualFormPayload = deepCloneObj(vertualPayload);
    let tempVertualError = deepCloneObj(vertualError)
    let isValid = true;

    try {
        tempvertualFormPayload.forEach((tab, tabIndes) => {
           
            if(!Array?.isArray(tab[0])){
               
    
                if(tab[0].type === "text") {
                    if(tab[0].required) {
                        if(isEmptyNullUndefined(tab[0].response.stringResponse)) {
                            
                            const responseError = new ResponseErrorConstructorFun(true, 'Required')
                            // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                            tempVertualError[tabIndes][0].responseError = responseError
    
                           
    
                            isValid = false
                        }
                        
                    }
    
                } else if(tab[0].type === "number") {
                    if(tab[0].required) {
                        if(isEmptyNullUndefined(tab[0].response.numberResponse)) {
                           
                            const responseError = new ResponseErrorConstructorFun(true, 'Required')
                            // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                            tempVertualError[tabIndes][0].responseError = responseError
                            isValid = false
                        }
                        
                    }
                } else if(tab[0].type === "select") {
                    if(tab[0].required) {
                        if(isEmptyNullUndefined(tab[0].response.selectResponse)) {
                            
                            const responseError = new ResponseErrorConstructorFun(true, 'Required')
                            // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                            tempVertualError[tabIndes][0].responseError = responseError
    
                           
    
                            isValid = false
                        }
                        
                    }
                } else if(tab[0].type === "date") {
                    if(tab[0].required) {
                        if(isEmptyNullUndefined(tab[0].response.dateResponse)) {

                            const responseError = new ResponseErrorConstructorFun(true, 'Required')
                            // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                            tempVertualError[tabIndes][0].responseError = responseError
    
                        
    
                            isValid = false
                        }
                        
                    }
                }
    
                if((tab.length > 1) && tab[1].type === "text") {
                    if(tab[1].required) {
                        if(isEmptyNullUndefined(tab[1].response.stringResponse)) {
                          
                            const responseError = new ResponseErrorConstructorFun(true, 'Required')
                            // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                            tempVertualError[tabIndes][1].responseError = responseError
    
                            isValid = false
                        }
                        
                    }
    
                } else if ((tab.length > 1) && tab[1].type === "number") {
                    if(tab[1].required) {
                        if(isEmptyNullUndefined(tab[1].response.numberResponse)) {
                            const responseError = new ResponseErrorConstructorFun(true, 'Required')
                            // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                           
                            tempVertualError[tabIndes][1].responseError = responseError
    
                            isValid = false
                        }
                        
                    }
                } else if ((tab.length > 1) && tab[1].type === "select") {
                    if(tab[1].required) {
                        if(isEmptyNullUndefined(tab[1].response.selectResponse)) {
                            const responseError = new ResponseErrorConstructorFun(true, 'Required')
                            // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                            tempVertualError[tabIndes][1].responseError = responseError
    
                            isValid = false
                        }
                        
                    }
                } else if ((tab.length > 1) && tab[1].type === "date") {
                    if(tab[1].required) {
                        if(isEmptyNullUndefined(tab[1].response.dateResponse)) {
                            const responseError = new ResponseErrorConstructorFun(true, 'Required')
                            // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                            tempVertualError[tabIndes][1].responseError = responseError
    
                            isValid = false
                        }
                        
                    }
                }
    
            } else {
                // this is container

                // =============
                tab.map((similarContainerE, similarContainerI) => {

                    similarContainerE[0].container.forEach((tile, tileIndex) => {

                        if(tile[0].type === "text") {
                            if(tile[0].required) {
                                if(isEmptyNullUndefined(tile[0].response.stringResponse)) {
                                    const responseError = new ResponseErrorConstructorFun(true, 'Required')
                                    // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                                    tempVertualError[tabIndes][similarContainerI][0].container[tileIndex][0].responseError = responseError
            
                                    isValid = false
                                }
                                
                            }
            
                        } else if(tile[0].type === "number") {
                            if(tile[0].required) {
                                if(isEmptyNullUndefined(tile[0].response.numberResponse)) {
                                    const responseError = new ResponseErrorConstructorFun(true, 'Required')
                                    // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                                    tempVertualError[tabIndes][similarContainerI][0].container[tileIndex][0].responseError = responseError
            
                                    isValid = false
                                }
                                
                            }
                        } else if(tile[0].type === "select") {
                            if(tile[0].required) {
                                if(isEmptyNullUndefined(tile[0].response.selectResponse)) {
                                    const responseError = new ResponseErrorConstructorFun(true, 'Required')
                                    // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                                    tempVertualError[tabIndes][similarContainerI][0].container[tileIndex][0].responseError = responseError
            
            
                                    isValid = false
                                }
                                
                            }
                        } else if(tile[0].type === "date") {
                            if(tile[0].required) {
                                if(isEmptyNullUndefined(tile[0].response.dateResponse)) {
                                    const responseError = new ResponseErrorConstructorFun(true, 'Required')
                                    // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                                    tempVertualError[tabIndes][similarContainerI][0].container[tileIndex][0].responseError = responseError
            
                                    isValid = false
                                }
                                
                            }
                        }

                        if((tile.length > 1) && tile[1].type === "text") {
                            if(tile[1].required) {
                                if(isEmptyNullUndefined(tile[1].response.stringResponse)) {
                                    const responseError = new ResponseErrorConstructorFun(true, 'Required')
                                    // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                                    tempVertualError[tabIndes][similarContainerI][0].container[tileIndex][1].responseError = responseError
            
                                    isValid = false
                                }
                                
                            }
            
                        } else if ((tile.length > 1) && tile[1].type === "number") {
                            if(tile[1].required) {
                                if(isEmptyNullUndefined(tile[1].response.numberResponse)) {
                                    const responseError = new ResponseErrorConstructorFun(true, 'Required')
                                    // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                                    tempVertualError[tabIndes][similarContainerI][0].container[tileIndex][1].responseError = responseError
            
                                    isValid = false
                                }
                                
                            }
                        } else if ((tile.length > 1) && tile[1].type === "select") {
                            if(tile[1].required) {
                                if(isEmptyNullUndefined(tile[1].response.selectResponse)) {
                                    const responseError = new ResponseErrorConstructorFun(true, 'Required')
                                    // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                                    tempVertualError[tabIndes][similarContainerI][0].container[tileIndex][1].responseError = responseError
            
                                    isValid = false
                                }
                                
                            }
                        } else if ((tile.length > 1) && tile[1].type === "date") {
                            if(tile[1].required) {
                                if(isEmptyNullUndefined(tile[1].response.dateResponse)) {
                                    const responseError = new ResponseErrorConstructorFun(true, 'Required')
                                    // const responseError = deepCloneObj(ResponseErrorConstructorObj(true, 'Required'))
                                    tempVertualError[tabIndes][similarContainerI][0].container[tileIndex][1].responseError = responseError
            
                                    isValid = false
                                }
                                
                            }
                        }

                    })

                    return similarContainerE

                })
            }
    
    
        })
    } catch (error) {
        console.log(errorCodeMessage, 'e545e4rr4ver5454v5r4v5r')
        console.log('error message', error.message)
    }



    return {isValid, tempVertualError}

}