import React from 'react';
import { Dialog, Button, Grid, Typography, MenuItem, Select, Box, TextField } from '@mui/material';
import EditUserProfile from "../../../../../assets/images/successionPlan/editUserProfile.svg";
import { AiOutlineDown } from "react-icons/ai";


const ProfileEditModal = ({ showProfileEdit, handleProfileEditNo }) => {

    const totalNumbers = [1, 2, 3, 4, 5];
    return (
        <Dialog
            open={showProfileEdit}
            onClose={handleProfileEditNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: "1rem",
                    backgroundColor: "#F9F9F9",
                }
            }}
            fullWidth
            maxWidth="md">
            <Grid container className="profile-edit">
                <Grid item xs={12} md={12} sm={12} className="profile-card">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2} sm={2} className="ta-center right-border">
                            <img
                                src={EditUserProfile}
                                alt="EditUserProfile"
                                height={50}
                                width={50}
                            />
                            <Typography className="profile-name">{"Akshay C "}</Typography>
                            <Typography className="profile-field-label">{"Emp No. "}<span className="profile-emp-no">{"10614"}</span></Typography>
                        </Grid>
                        <Grid item xs={12} md={10} sm={10}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Current Role"}</Typography>
                                    <Typography className="profile-field-value">{"VP Retail - Category"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Tenure in Role (# months)"}</Typography>
                                    <Typography className="profile-field-value">{"9 Months"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Tenure in Level (# months)"}</Typography>
                                    <Typography className="profile-field-value">{"9 Months"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Mentor if existing or known"}</Typography>
                                    <Typography className="profile-field-value">{"-"}</Typography>
                                </Grid>

                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Geographic Mobility (if yes, regions)"}</Typography>
                                    <Typography className="profile-field-value">{"Yes, Moving to Seattle in Summer"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Performance Rating 2024"}</Typography>
                                    <Typography className="profile-field-value">{"B"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Behavior Rating 2024"}</Typography>
                                    <Typography className="profile-field-value">{"A"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Performance Rating 2023:"}</Typography>
                                    <Typography className="profile-field-value">{"A"}</Typography>
                                </Grid>

                                <Grid item xs={12} md={3} sm={3}>
                                    <Typography className="profile-field-label">{"Behavior Rating 2023"}</Typography>
                                    <Typography className="profile-field-value">{"A"}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/*----- Rate Competency -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="rate-competency">{"Rate Competency"}</Typography>
                    <Grid className="rate-competency-grid">
                        <Grid>
                            <Typography className="select-field-label">{"Customer Obsession"}</Typography>

                            <Select
                                size="small"
                                name="promotionStatus"
                                fullWidth
                                value={"5"}
                                className="text-field-area"
                                IconComponent={AiOutlineDown}
                            >
                                {totalNumbers?.map((report) => {
                                    return (
                                        <MenuItem value={report} key={report}>{report}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid>
                            <Typography className="select-field-label">{"Ownership"}</Typography>

                            <Select
                                size="small"
                                name="promotionStatus"
                                fullWidth
                                value={"3"}
                                className="text-field-area"
                                IconComponent={AiOutlineDown}
                            >
                                {totalNumbers?.map((report) => {
                                    return (
                                        <MenuItem value={report} key={report}>{report}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid>
                            <Typography className="select-field-label">{"Invent And Simplify"}</Typography>

                            <Select
                                size="small"
                                name="promotionStatus"
                                fullWidth
                                value={"3"}
                                className="text-field-area"
                                IconComponent={AiOutlineDown}
                            >
                                {totalNumbers?.map((report) => {
                                    return (
                                        <MenuItem value={report} key={report}>{report}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid>
                            <Typography className="select-field-label">{"Are  Right A Lot"}</Typography>

                            <Select
                                size="small"
                                name="promotionStatus"
                                fullWidth
                                value={"4"}
                                className="text-field-area"
                                IconComponent={AiOutlineDown}
                            >
                                {totalNumbers?.map((report) => {
                                    return (
                                        <MenuItem value={report} key={report}>{report}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid>
                            <Typography className="select-field-label">{"Learn and Be Curious"}</Typography>

                            <Select
                                size="small"
                                name="promotionStatus"
                                fullWidth
                                value={"3"}
                                className="text-field-area"
                                IconComponent={AiOutlineDown}
                            >
                                {totalNumbers?.map((report) => {
                                    return (
                                        <MenuItem value={report} key={report}>{report}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid className="rate-competency-grid">
                        <Grid>
                            <Typography className="select-field-label">{"Hire and Develop the Best"}</Typography>

                            <Select
                                size="small"
                                name="promotionStatus"
                                fullWidth
                                value={"2"}
                                className="text-field-area"
                                IconComponent={AiOutlineDown}
                            >
                                {totalNumbers?.map((report) => {
                                    return (
                                        <MenuItem value={report} key={report}>{report}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid>
                            <Typography className="select-field-label">{"Insist on the Highest Standards"}</Typography>

                            <Select
                                size="small"
                                name="promotionStatus"
                                fullWidth
                                value={"5"}
                                className="text-field-area"
                                IconComponent={AiOutlineDown}
                            >
                                {totalNumbers?.map((report) => {
                                    return (
                                        <MenuItem value={report} key={report}>{report}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid></Grid>
                        <Grid></Grid>
                        <Grid></Grid>
                    </Grid>

                    <Grid className='profile-horizontal-border' />
                </Grid>


                {/*----- Development Opportunity Areas -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="select-field-label">{"Development Opportunity Areas"}</Typography>

                    <TextField
                        name="devOppAreas"
                        variant="outlined"
                        placeholder="Type here..."
                        fullWidth
                        multiline
                        rows={9}
                        value={"Will continue to keep his focus on his primarily development areas that he has been working over the last couple of years. Additionally, I would like him to Thing Big and Strategically as a VP (showcase this in areas outside of the standard input focus). Development area carried from previous LPA -- Ability to Earn Trust across organizational boundaries and think outside the standard Amazon model and ability to understand the emotional side-effects of his communication style or actions on his own self and people around him. Style can sometimes come across as abrasive and close minded causing him to be misunderstood, and it makes him defensive and feel targeted (including extremely emotional) when this happens causing him to act in ways that could further erode trust. He can also develop strong opinions about people, teams, initiatives and that affects his ability to influence change unemotionally and objectively. He disagrees sometimes even without considering the alternate opinion. Finally, able to zoom out and think strategically without being frustrated about tactical issues. His deep knowledge in operations also boxes him in a certain way of thinking relative to what is possible."}
                        className="text-field-area"
                    />
                </Grid>

                {/*----- Action steps to address the Gaps -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="select-field-label">{"Action steps to address the Gaps"}</Typography>

                    <TextField
                        name="actionAddressGaps"
                        variant="outlined"
                        placeholder="Type here..."
                        fullWidth
                        multiline
                        rows={5}
                        value={"He has used an external coach and has tools to mitigate the trigger points. He has done well practicing that and should continue to do so in future as well."}
                        className="text-field-area"
                    />
                </Grid>

                {/*----- Does the current role maximize the person's strengths? -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="select-field-label">{"Does the current role maximize the person's strengths?"}</Typography>

                    <TextField
                        name="actionAddressGaps"
                        variant="outlined"
                        placeholder="Type here..."
                        fullWidth
                        multiline
                        rows={5}
                        value={"He has used an external coach and has tools to mitigate the trigger points. He has done well practicing that and should continue to do so in future as well."}
                        className="text-field-area"
                    />
                </Grid>

            </Grid>
        </Dialog>
    );
};

export default ProfileEditModal;